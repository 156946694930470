import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailListForm from "../components/emailListForm"

class IndexPage extends React.Component {
  renderBlogRoll = posts => {
    const colors = [
      "bg-red-100",
      "bg-blue-100",
      "bg-orange-100",
      "bg-green-100",
    ]
    const results = []
    posts.forEach(({ node }, index) => {
      results.push(
        <div className={`py-8 px-12 mb-4 ${colors[index]} rounded`}>
          <Link to={`blog${node.fields.slug}`}>
            <small className="tracking-wide">{node.frontmatter.date}</small>
            <h3 className="mt-1 text-2xl font-bold">
              {node.frontmatter.title}
            </h3>
          </Link>
        </div>
      )
    })
    return results.slice(0, 3)
  }

  render() {
    const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const siteTitle = "Justin Liwag Blog Home Page"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div className="mt-16 md:mt-32">
          <div className="text-5xl font-bold leading-none text-blue-400">
            Justin Liwag
          </div>
          <h3 className="mt-3 text-lg font-bold text-gray-800">
            Entrepreneur, Designer, Developer, occasional magician.
          </h3>
          <p className="max-w-2xl mt-2">
            Hi, my name is Justin and this is my personal site. This is the home
            for anything I am working on, writing about or reading. I am an
            entrepreneur that has run two companies and have been a playing card
            shuffler for 15 years. I like to write and think about how good
            design, technology and good old fashioned sleight of hand merge
            together.
            <br />
            <br />
            You can check out what I am currently reading on my{" "}
            <a
              className="italic font-bold text-blue-400 underline"
              href="https://www.notion.so/Read-ing-List-96d87fa62d584af2bf68ea5bc9c6e215"
            >
              Read[ing]
            </a>{" "}
            page as well as any cool or interesting stuff that I stumble across
            on my public{" "}
            <a
              className="italic font-bold text-blue-400 underline"
              href="https://www.notion.so/justinliwag/Public-External-Brain-93a46459b77c4a6fa5d23bbfa1e97957"
            >
              External Brain
            </a>{" "}
            .
          </p>
        </div>
        <br />
        <div className="mt-16">
          <h2 className="text-2xl font-bold ">Recent Writing</h2>
          <p className="max-w-lg mt-2">
            Here are the most recent articles I have written. I think some of
            them have typos.
          </p>
          <div className="mt-8">{this.renderBlogRoll(posts)}</div>
        </div>
        <Link to="/blog/">
          <button className="px-8 py-2 mt-6 text-white transition duration-150 ease-in-out bg-gray-800 rounded hover:bg-blue-700">
            View Archive
          </button>
        </Link>

        <div className="mt-24">
          <EmailListForm />
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
